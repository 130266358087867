<template>
  <div>
    <v-row justify="end">
      <v-col><h2>ລາຍງານຕຸກນ້ຳມີບັນຫາ</h2></v-col>
<!--      <v-col class="al-end">-->
<!--        <v-btn-->
<!--          color="primary"-->
<!--          @click="exportData()"-->
<!--          :loading="exportLoading"-->
<!--          :disabled="exportLoading"-->
<!--          >Export</v-btn-->
<!--        ></v-col-->
<!--      >-->
    </v-row>
    <!-- <v-row class="my-2" justify="end">
      <v-col><h2>ທັງໝົດ {{}}</h2></v-col>
    </v-row> -->
    <!-- <hr /> -->

    <v-row>
      <v-col cols="4">
        <v-menu
            v-model="start_menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="start_date"
                label="ເລີ່ມວັນທີ"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                dense
                clearable
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="start_date"
              @input="fetchData()"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="selectedStore"
          :items="store_Types"
          label="ບ່ອນເກີດບັນຫາ"
          item-text="name"
          item-value="value"
          dense
          outlined
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
            outlined
            dense
            clearable
            label="Barcode"
            type="text"
            v-model="search"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mb-1 mt-0">
<!--      <v-col>-->
<!--        <h3 class="success&#45;&#45;text">-->
<!--          ລວມ: {{ Intl.NumberFormat().format(sumTotalImportBottle) }} ຕຸກ-->
<!--        </h3>-->
<!--      </v-col>-->
      <!--
      <v-col>
        <h3 class="primary--text">
          ຕຸກນ້ຳຢູ່ໂຮງງານ:
          {{ Intl.NumberFormat().format(sumTotalImportBottle) }}
        </h3>
      </v-col>
     
      <v-col>
        <h3 class="info--text">
          ຕຸກເປົ່າເຂົ້າໂຮງງານ:
          {{ Intl.NumberFormat().format(sumTotalImportBottle) }}
        </h3>
      </v-col>
      -->
    </v-row>
    <!-- <hr /> -->
    <v-data-table
      :headers="headers"
      :items="issue_bottles"
      :items-per-page="15"
      class="elevation-1"
      :loading="TableLoading"
      :disabled="TableLoading"
      :search="search"
      loading-text="Loading... Please wait"
    >
      <template v-slot:item.created_at="{ item }">
        {{ moment(item.date).format("DD-MM-YYYY hh:mm:ss") }}
        <!-- {{item.created_at}} -->
      </template>
    </v-data-table>
    <br />
    <Pagination
        v-if="pagination.last_page > 1"
        :pagination="pagination"
        :offset="offset"
        @paginate="fetchLostBottle()"
    ></Pagination>
  </div>
</template>

<script>
import Pagination from "@/plugins/pagination/pagination";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      start_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
      end_date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      start_menu: false,
      end_menu: false,

      loading: false,
      exportLoading: false,
      TableLoading: false,
      issue_bottles: [],
      //Sum Variable
      sumTotalExportBottle: 0,
      sumTotalImportBottle: 0,

      //Pagination
      offset: 12,
      pagination: {},
      per_page: 15,
      search:"",
      //Payment Type
      selectedStore: "all",
      store_Types: [
        {
          value: "all",
          name: "All",
        }, {
          value: "factory",
          name: "ໂຮງງານ",
        }, {
          value: "truck",
          name: "ລົດຂົນສົ່ງ",
        }, {
          value: "branch",
          name: "ສາຂາ",
        }, {
          value: "customer",
          name: "ລູກຄ້າ",
        },
      ],

      headers: [
        {
          text: "ລະຫັດຕຸກນ້ຳ",
          align: "start",
          sortable: false,
          value: "barcode",
        },
        { text: "ຜູ້ຮັບຜິດຊອບ", value: "create_by", sortable: false },
        { text: "ລາຍລະອຽດ", value: "reason", sortable: false },
        { text: "ບ່ອນເກີດບັນຫາ", value: "type" },
        { text: " ວັນທີ", value: "created_at" },
      ],
    };
  },
  metaInfo() {
    return {
      title: `ລາຍງານຍອດຕຸກນ້ຳມີບັນຫາ`,
    }
  },
  methods: {
    fetchData() {
      // (this.pagination.current_page);
       if(this.start_date == null){
         this.start_date = 'all'
       }
      this.TableLoading = true;
      this.$admin
        .post("/report-issue-bottles", {
          // startDate: this.start_date,
          // page: this.pagination.current_page,
          // per_page: this.per_page,
          "barcode": "all",
          "create_by": "all",
          "from_id": "all",
          "type": this.selectedStore,
          "date": this.start_date,
        })
        .then((res) => {
          setTimeout(() => {
            this.issue_bottles = res.data.issue_bottles.data;
            // this.pagination = res.data.issue_bottles;
            // this.start_menu = false;
            // this.end_menu = false;
            this.TableLoading = false;
          }, 100);
        })
        .catch(() => {
          this.TableLoading = false;
          this.end_menu = false;
          this.start_menu = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },

    //Export excel
    exportData() {
      this.exportLoading = true;
      this.$axios
        .post(
          "export/factory/import/and/export/bottles",
          {
            startDate: this.start_date,
            endDate: this.end_date,
            store_type: this.selectedStore,
          },
          { responseType: "blob" }
        )
        .then((res) => {
          setTimeout(() => {
            const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
            const fileLink = document.createElement("a");
            fileLink.href = fileUrl;
            fileLink.setAttribute(
              "download",
              "ລາຍງານຍອດຕຸກນ້ຳ "+this.start_date +"_"+ this.end_date + ".xlsx"
            );
            document.body.appendChild(fileLink);
            fileLink.click();
            document.body.removeChild(fileLink);
            this.exportLoading = false;
          }, 100);
        })
        .catch(() => {
          this.exportLoading = false;
          this.$store.commit("Toast_State", this.toast_error);
        });
    },
  },
  watch: {
    selectedStore: function() {
      this.pagination.current_page = '';
      this.fetchData();
    },
    start_date: function(value) {
      if(value){
        this.pagination.current_page = '';
        this.fetchData();
      } else {
        this.start_date = 'all';
        this.pagination.current_page = '';
        this.fetchData();
      }
    }

  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
.al-end {
  text-align: end !important;
}
.invoice {
  font-size: 16px;
  color: #000000;
}
.primary-color {
  color: #00c1d2;
}
</style>
